import feathers from "@feathersjs/client";
import rest from "@feathersjs/rest-client";
import axios from "axios";
import cookie from "cookie";

const cookieDomain = 'lab.scribedevelopers.com';
const cookieMaxAge = 365*24*60*60;

const cookieStorage = (cookieName) => ({
    getItem(name) {
        return cookie.parse(document.cookie)[cookieName];
    },
    setItem(name, newValue) {
        document.cookie = cookie.serialize(cookieName, newValue, {
            domain: cookieDomain,
            maxAge: cookieMaxAge,
            sameSite: 'strict',
          });
    },
    removeItem(name) {
        document.cookie = cookie.serialize(cookieName, '', {
            domain: cookieDomain,
            expires: new Date(),
          });
    },
});

const feathersClient = feathers();
const restClient = rest(process.env.REACT_APP_API_URL);

feathersClient.configure(restClient.axios(axios));
feathersClient.configure(feathers.authentication({
    storage: cookieStorage('cccToken'),
}));

export default feathersClient;