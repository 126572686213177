import { useState, useCallback } from 'react';
import feathersClient from '../client';
import emailRegex from '../emailRegex';
import cookie from 'cookie';

function LoginForm() {
  const [credentials, setCredentials] = useState({ emailOrUsername: '', password: '' });
  const login = useCallback(async (e) => {
    e.preventDefault();
    try {
      let authenticationResult = {};
      if (emailRegex.test(credentials.emailOrUsername)) {
        authenticationResult = await feathersClient.authenticate({
          strategy: 'local',
          email: credentials.emailOrUsername,
          password: credentials.password
        });
      }
      else {
        authenticationResult = await feathersClient.authenticate({
          strategy: 'local-username',
          username: credentials.emailOrUsername,
          password: credentials.password
        });
      }
      if (!!authenticationResult.accessToken)
        document.cookie = cookie.serialize('cccToken', authenticationResult.accessToken, {
          domain: 'lab.scribedevelopers.com',
          maxAge: 30,
          sameSite: 'strict',
        });
      window.location.pathname = '/my-account';
    } catch (error) {
      throw Error(error);
    }
  }, [credentials]);
  return (
    <div>
      <h2 style={{ textAlign: 'center', width: '100%' }}>
        Log into the Cosmic Cubs Club!
      </h2>
      <form
        onSubmit={login}
      >
        <input
          id='email'
          type='text'
          placeholder='Your email or username'
          name='email'
          value={credentials.emailOrUsername}
          onChange={e => setCredentials({ ...credentials, emailOrUsername: e.target.value })}
        />
        <input
          id='password'
          type='password'
          placeholder='Your password'
          name='password'
          value={credentials.password}
          onChange={e => setCredentials({ ...credentials, password: e.target.value })}
        />
        <button
          className='full-width'
          type='submit'
        >
          Sign In
        </button>
      </form>
      <p style={{ textAlign: "center", width: "100%", paddingTop: "10px" }}>Not a member yet?  <a href="/signup">Sign up!</a></p>
    </div>
  );
}

export default LoginForm;