import './App.css';
import LoginForm from './components/LoginForm';
import SignupForm from './components/SignupForm';
import MyAccountPage from './components/MyAccountPage';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/signup',
    element: <SignupForm />
  },
  {
    path: '/login',
    element: <LoginForm />
  },
  {
    path: '/my-account',
    element: <MyAccountPage />
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
