import { useState, useCallback } from 'react';
import feathersClient from '../client';
import emailRegex from '../emailRegex';

function SignupForm() {
    const [formValues, setFormValues] = useState({
        email: '',
        password: '',
        passwordVerify: '',
        username: '',
        firstName: '',
        lastName: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const signup =  useCallback(async (e) => {
      e.preventDefault();
      try {
        if(!emailRegex.test(formValues.email)) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }
        if(!formValues.username.length) {
          setErrorMessage('You must pick a username.')
          return;
        }
        if(formValues.password.length < 8) {
            setErrorMessage('Password must be at least 8 characters.')
            return;
        }
        if(formValues.password !== formValues.passwordVerify) {
          setErrorMessage('Passwords do not match.');
          return;
        }
        await feathersClient.service('users').create({
          email: formValues.email,
          password: formValues.password,
          username: formValues.username,
          ...(formValues.firstName.length ? { firstName: formValues.firstName } : {}),
          ...(formValues.lastName.length ? { lastName: formValues.lastName } : {})
        });
        window.history.go('/login');
      }  catch(error) {
        setErrorMessage('Something went wrong.  Please try again.')
        throw Error(error);
      }
    }, [formValues, setErrorMessage]);
    return (
      <div>
        <h2 style={{ textAlign: 'center', width: '100%' }}>
          Sign up for the Cosmic Cubs Club!
        </h2>
        <form
          onSubmit={signup}
        >
          <input
            id='email'
            type='email'
            placeholder='Your email address'
            name='email'
            value={formValues.email}
            onChange={e => setFormValues({ ...formValues, email: e.target.value })}
          />
          <input
            id='username'
            type='text'
            placeholder='Choose a username'
            name='username'
            value={formValues.username}
            onChange={e => setFormValues({ ...formValues, username: e.target.value })}
          />
          <input
            id='password'
            type='password'
            placeholder='Choose a password'
            name='password'
            value={formValues.password}
            onChange={e => setFormValues({ ...formValues, password: e.target.value })}
          />
          <input
            id='password-verify'
            type='password'
            placeholder='Verify your password'
            name='passwordVerify'
            value={formValues.passwordVerify}
            onChange={e => setFormValues({ ...formValues, passwordVerify: e.target.value })}
          />
          <input
            id='first-name'
            type='text'
            placeholder='Your first name'
            name='firstName'
            value={formValues.firstName}
            onChange={e => setFormValues({ ...formValues, firstName: e.target.value })}
          />
          <input
            id='last-name'
            type='text'
            placeholder='Your last name'
            name='lastName'
            value={formValues.lastName}
            onChange={e => setFormValues({ ...formValues, lastName: e.target.value })}
          />
          <button
            className='full-width'
            type='submit'
          >
            Sign Up
          </button>
          {
            errorMessage && errorMessage.length
                ? <span className='error-text'>{errorMessage}</span>
                : <></>
          }
        </form>
        <p style={{ textAlign: "center", width: "100%", paddingTop: "10px" }}>Already a member?  <a href="/login">Sign in!</a></p>
      </div>
    );
  }
  
  export default SignupForm;