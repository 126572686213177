import { useState, useCallback, useEffect } from 'react';
import feathersClient from '../client';
import emailRegex from '../emailRegex';

function MyAccountPage() {
    const [accountUser, setAccountUser] = useState({
        _id: null,
        email: '',
        username: '',
        firstName: '',
        lastName: '',
        password: '',
        passwordVerify: '',
        parentId: null,
    });
    const [childUsers, setChildUsers] = useState([]);
    const [editingSelf, setEditingSelf] = useState(false);
    const [addChildMode, setAddChildMode] = useState(false);
    const [editingChild, setEditingChild] = useState(null);
    const [newChildFormValues, setNewChildFormValues] = useState({
        password: '',
        passwordVerify: '',
        username: '',
        firstName: '',
        lastName: ''
    });
    const [newChildErrorMessage, setNewChildErrorMessage] = useState('');
    const [editingChildFormValues, setEditingChildFormValues] = useState({
        password: '',
        passwordVerify: '',
        username: '',
        firstName: '',
        lastName: ''
    });
    const [editingSelfErrorMessage, setEditingSelfErrorMessage] = useState('');
    const [editingSelfFormValues, setEditingSelfFormValues] = useState({
        email: '',
        password: '',
        passwordVerify: '',
        username: '',
        firstName: '',
        lastName: ''
    });
    const [editingChildErrorMessage, setEditingChildErrorMessage] = useState('');
    const getAccountUser = useCallback(() => {
        (async () => {
            await feathersClient.reAuthenticate();
            let authenticationResult = await feathersClient.get('authentication');
            let user = await feathersClient.service('users').get(authenticationResult.user._id);
            setAccountUser(user);
        })().catch(e => { throw Error(e); });
    }, [setChildUsers, setAccountUser]);
    const getChildUsers = useCallback(() => {
        (async () => {
            await feathersClient.reAuthenticate();
            let authenticationResult = await feathersClient.get('authentication');
            feathersClient.service('users').find().then(async users => {
                setChildUsers(users.data.filter(user => user._id !== authenticationResult.user._id));
                setChildUsers(users.data.filter(user => user._id !== authenticationResult.user._id));
                setAccountUser(users.data.find(user => user._id !== authenticationResult.user));
            });
        })().catch(e => { throw Error(e); });
    }, [setChildUsers, setAccountUser]);
    const logout = useCallback(async (e) => {
        e.preventDefault();
        await feathersClient.logout();
        window.location.pathname = '/login';
    });
    const saveChangesToChild = useCallback(async (e) => {
        e.preventDefault();
        try {
            if (!!editingChildFormValues.password && editingChildFormValues.password.length && editingChildFormValues.password.length < 8) {
                setEditingChildErrorMessage('Password must be at least 8 characters.')
                return;
            }
            if (editingChildFormValues.password !== editingChildFormValues.passwordVerify) {
                setEditingChildErrorMessage('Passwords do not match.');
                return;
            }
            await feathersClient.service('users').patch(editingChild, {
                ...(!!editingChildFormValues.username && editingChildFormValues.username.length ? { username: editingChildFormValues.username } : {}),
                ...(!!editingChildFormValues.password && editingChildFormValues.password.length ? { password: editingChildFormValues.password } : {}),
                ...(!!editingChildFormValues.firstName && editingChildFormValues.firstName.length ? { firstName: editingChildFormValues.firstName } : { firstName: '' }),
                ...(!!editingChildFormValues.lastName && editingChildFormValues.lastName.length ? { lastName: editingChildFormValues.lastName } : { lastName: '' })
            });
            setEditingChild(null);
            setEditingChildErrorMessage('');
            getChildUsers();
        } catch (error) {
            setEditingChildErrorMessage('Something went wrong.  Please try again.')
            throw Error(error);
        }
    }, [editingChildFormValues, setEditingChildErrorMessage]);
    const saveChangesToSelf = useCallback(async (e) => {
        e.preventDefault();
        try {
            if (!accountUser.parentId && !!editingSelfFormValues.email && !emailRegex.test(editingSelfFormValues.email)) {
                setEditingSelfErrorMessage('Please enter a valid email address.');
                return;
            }
            if (!!editingSelfFormValues.password && editingSelfFormValues.password.length && editingSelfFormValues.password.length < 8) {
                setEditingSelfErrorMessage('Password must be at least 8 characters.')
                return;
            }
            if (editingSelfFormValues.password !== editingSelfFormValues.passwordVerify) {
                setEditingSelfErrorMessage('Passwords do not match.');
                return;
            }
            let authenticationResult = await feathersClient.get('authentication');
            await feathersClient.service('users').patch(authenticationResult.user._id, {
                ...(!accountUser.parentId && !!editingSelfFormValues.email && editingSelfFormValues.email.length ? { email: editingSelfFormValues.email } : {}),
                ...(!!editingSelfFormValues.username && editingSelfFormValues.username.length ? { username: editingSelfFormValues.username } : {}),
                ...(!!editingSelfFormValues.password && editingSelfFormValues.password.length ? { password: editingSelfFormValues.password } : {}),
                ...(!!editingSelfFormValues.firstName && editingSelfFormValues.firstName.length ? { firstName: editingSelfFormValues.firstName } : { firstName: '' }),
                ...(!!editingSelfFormValues.lastName && editingSelfFormValues.lastName.length ? { lastName: editingSelfFormValues.lastName } : { lastName: '' })
            });
            setEditingSelf(false);
            setEditingSelfErrorMessage('');
            getAccountUser();
        } catch (error) {
            setEditingSelfErrorMessage('Something went wrong.  Please try again.')
            throw Error(error);
        }
    }, [editingSelfFormValues, setEditingSelfErrorMessage]);
    const signupNewChild = useCallback(async (e) => {
        e.preventDefault();
        try {
            if (!newChildFormValues.username.length) {
                setNewChildErrorMessage('You must pick a username.')
                return;
            }
            if (newChildFormValues.password.length < 8) {
                setNewChildErrorMessage('Password must be at least 8 characters.')
                return;
            }
            if (newChildFormValues.password !== newChildFormValues.passwordVerify) {
                setNewChildErrorMessage('Passwords do not match.');
                return;
            }
            let authenticationResult = await feathersClient.get('authentication');
            await feathersClient.service('users').create({
                password: newChildFormValues.password,
                username: newChildFormValues.username,
                ...(newChildFormValues.firstName.length ? { firstName: newChildFormValues.firstName } : {}),
                ...(newChildFormValues.lastName.length ? { lastName: newChildFormValues.lastName } : {}),
                parentId: authenticationResult.user._id
            });
            setAddChildMode(false);
            setNewChildErrorMessage('');
            getChildUsers();
        } catch (error) {
            setNewChildErrorMessage('Something went wrong.  Please try again.')
            throw Error(error);
        }
    }, [newChildFormValues, setNewChildErrorMessage]);
    useEffect(() => {
        feathersClient.authenticate().catch(() => window.location.pathname = "/login");
        getChildUsers();
        getAccountUser();
    }, []);
    return (
        <div>
            <h2 style={{ textAlign: 'center', width: '100%' }}>
                My Cosmic Cubs Club account
            </h2>

            <div id='account-details'>
                {
                    editingSelf
                        ? <>
                            <form
                                onSubmit={saveChangesToSelf}
                            >
                                {
                                    !accountUser.parentId
                                        ? <input
                                            id='email'
                                            type='email'
                                            placeholder='Your email address'
                                            name='email'
                                            value={editingSelfFormValues.email}
                                            onChange={e => setEditingSelfFormValues({ ...editingSelfFormValues, email: e.target.value })}
                                        />
                                        : <></>
                                }
                                <input
                                    id='username'
                                    type='text'
                                    placeholder='Choose a username'
                                    name='username'
                                    value={editingSelfFormValues.username}
                                    onChange={e => setEditingSelfFormValues({ ...editingSelfFormValues, username: e.target.value })}
                                />
                                <input
                                    id='password'
                                    type='password'
                                    placeholder='Choose a password'
                                    name='password'
                                    value={editingSelfFormValues.password}
                                    onChange={e => setEditingSelfFormValues({ ...editingSelfFormValues, password: e.target.value })}
                                />
                                <input
                                    id='password-verify'
                                    type='password'
                                    placeholder='Verify password'
                                    name='passwordVerify'
                                    value={editingSelfFormValues.passwordVerify}
                                    onChange={e => setEditingSelfFormValues({ ...editingSelfFormValues, passwordVerify: e.target.value })}
                                />
                                <input
                                    id='first-name'
                                    type='text'
                                    placeholder='First name'
                                    name='firstName'
                                    value={editingSelfFormValues.firstName}
                                    onChange={e => setEditingSelfFormValues({ ...editingSelfFormValues, firstName: e.target.value })}
                                />
                                <input
                                    id='last-name'
                                    type='text'
                                    placeholder='Last name'
                                    name='lastName'
                                    value={editingSelfFormValues.lastName}
                                    onChange={e => setEditingSelfFormValues({ ...editingSelfFormValues, lastName: e.target.value })}
                                />
                                <button
                                    className='full-width'
                                    type='submit'
                                >
                                    Save Changes
                                </button>
                                {
                                    editingSelfErrorMessage && editingSelfErrorMessage.length
                                        ? <span className='error-text'>{editingSelfErrorMessage}</span>
                                        : <></>
                                }
                            </form>
                        </>
                        : <>
                            {
                                !accountUser.parentId
                                    ? <><span>Email: {accountUser ? accountUser.email : ''}</span><br /></>
                                    : <></>
                            }
                            <span>Username: {accountUser ? accountUser.username : ''}</span><br />
                            <span>First Name: {accountUser ? accountUser.firstName : ''}</span><br />
                            <span>Last Name: {accountUser ? accountUser.lastName : ''}</span><br />
                            <button className='small' onClick={() => {
                                setEditingSelfFormValues({
                                    ...(!accountUser.parentId ? { email: accountUser.email } : {}),
                                    username: accountUser.username,
                                    firstName: accountUser.firstName,
                                    lastName: accountUser.lastName
                                });
                                setEditingSelf(true);
                            }}>Edit my details</button>

                        </>
                }
            </div>

            {
                !!accountUser && !accountUser.parentId ?
                    <div>
                        <h3>My children:</h3>
                        {
                            childUsers.map((user, i) => {
                                return <div key={i}>
                                    {
                                        editingChild === user._id
                                            ? <>
                                                <form
                                                    onSubmit={saveChangesToChild}
                                                >
                                                    <input
                                                        id='username'
                                                        type='text'
                                                        placeholder='Choose a username'
                                                        name='username'
                                                        value={editingChildFormValues.username}
                                                        onChange={e => setEditingChildFormValues({ ...editingChildFormValues, username: e.target.value })}
                                                    />
                                                    <input
                                                        id='password'
                                                        type='password'
                                                        placeholder='Choose a password'
                                                        name='password'
                                                        value={editingChildFormValues.password}
                                                        onChange={e => setEditingChildFormValues({ ...editingChildFormValues, password: e.target.value })}
                                                    />
                                                    <input
                                                        id='password-verify'
                                                        type='password'
                                                        placeholder='Verify password'
                                                        name='passwordVerify'
                                                        value={editingChildFormValues.passwordVerify}
                                                        onChange={e => setEditingChildFormValues({ ...editingChildFormValues, passwordVerify: e.target.value })}
                                                    />
                                                    <input
                                                        id='first-name'
                                                        type='text'
                                                        placeholder='First name'
                                                        name='firstName'
                                                        value={editingChildFormValues.firstName}
                                                        onChange={e => setEditingChildFormValues({ ...editingChildFormValues, firstName: e.target.value })}
                                                    />
                                                    <input
                                                        id='last-name'
                                                        type='text'
                                                        placeholder='Last name'
                                                        name='lastName'
                                                        value={editingChildFormValues.lastName}
                                                        onChange={e => setEditingChildFormValues({ ...editingChildFormValues, lastName: e.target.value })}
                                                    />
                                                    <button
                                                        className='full-width'
                                                        type='submit'
                                                    >
                                                        Save Changes
                                                    </button>
                                                    {
                                                        editingChildErrorMessage && editingChildErrorMessage.length
                                                            ? <span className='error-text'>{editingChildErrorMessage}</span>
                                                            : <></>
                                                    }
                                                </form>
                                            </>
                                            : <>
                                                <span>Username: {user.username} </span>
                                                {!editingChild
                                                    ? <button
                                                        className='small'
                                                        onClick={() => {
                                                            setEditingChildFormValues({
                                                                username: user.username,
                                                                firstName: user.firstName,
                                                                lastName: user.lastName
                                                            });
                                                            setEditingChild(user._id);
                                                        }}>
                                                        Edit
                                                    </button>
                                                    : <></>
                                                }
                                                <br />
                                            </>
                                    }
                                </div>
                            })
                        }
                        {
                            addChildMode
                                ? <>
                                    <h4>New child account:</h4>
                                    <form
                                        onSubmit={signupNewChild}
                                    >
                                        <input
                                            id='username'
                                            type='text'
                                            placeholder='Choose a username'
                                            name='username'
                                            value={newChildFormValues.username}
                                            onChange={e => setNewChildFormValues({ ...newChildFormValues, username: e.target.value })}
                                        />
                                        <input
                                            id='password'
                                            type='password'
                                            placeholder='Choose a password'
                                            name='password'
                                            value={newChildFormValues.password}
                                            onChange={e => setNewChildFormValues({ ...newChildFormValues, password: e.target.value })}
                                        />
                                        <input
                                            id='password-verify'
                                            type='password'
                                            placeholder='Verify password'
                                            name='passwordVerify'
                                            value={newChildFormValues.passwordVerify}
                                            onChange={e => setNewChildFormValues({ ...newChildFormValues, passwordVerify: e.target.value })}
                                        />
                                        <input
                                            id='first-name'
                                            type='text'
                                            placeholder='First name'
                                            name='firstName'
                                            value={newChildFormValues.firstName}
                                            onChange={e => setNewChildFormValues({ ...newChildFormValues, firstName: e.target.value })}
                                        />
                                        <input
                                            id='last-name'
                                            type='text'
                                            placeholder='Last name'
                                            name='lastName'
                                            value={newChildFormValues.lastName}
                                            onChange={e => setNewChildFormValues({ ...newChildFormValues, lastName: e.target.value })}
                                        />
                                        <button
                                            className='full-width'
                                            type='submit'
                                        >
                                            Sign Up
                                        </button>
                                        {
                                            newChildErrorMessage && newChildErrorMessage.length
                                                ? <span className='error-text'>{newChildErrorMessage}</span>
                                                : <></>
                                        }
                                    </form>
                                </>
                                : <>
                                    <button onClick={() => setAddChildMode(true)}>Add a child</button>
                                </>
                        }
                    </div>
                    :
                    <></>
            }
            <button className='full-width' onClick={logout}>Log Out</button>
        </div>
    )
}

export default MyAccountPage;